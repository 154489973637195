<template>
  <div>
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="6"
          class="m-0 p-0"
        >
          <b-card
            class="m-0 p-0"
            style="border-radius: 1rem;"
          >
            <b-button
              v-if="(parseInt(indexMessage) > 0)"
              size="sm"
              variant="primary"
              style="margin-bottom: 0.4rem;"
              @click="onPrev"
            >
              Prev
            </b-button>
            <div>
              <line-chat-message
                :data="jsonMessage"
                @action="onAction"
              />
            </div>
            <div
              v-if="jsonData !== null"
              class="mt-1"
            >
              <div>
                <b-tabs>
                  <b-tab
                    v-for="(action, index) in jsonData"
                    :key="index"
                    :title="`${$t('Area')} ${index+1}`"
                  >
                    <div
                      style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                    >
                      <b-form-group
                        v-if="action.area"
                        :label="$t('Area')"
                      >
                        <div
                          style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                        >
                          <b-row>
                            <b-col
                              v-for="(value, key) in action.area"
                              :key="key"
                            >
                              <span>{{ key }}</span>
                              <b-form-input
                                :value="value"
                                style="margin-top: 5px;"
                              />
                            </b-col>
                          </b-row>
                        </div>
                      </b-form-group>
                      <div style="padding: 0 1rem 0 1rem;">
                        <span style="font-size: 18px; font-weight: 500;">
                          {{ $t(textFirstUpper(action.type)) }}
                        </span>
                      </div>
                      <div style="padding: 0.4rem 1rem 0.4rem 1rem;">
                        <a
                          v-if="action.linkUri && action.type === 'uri'"
                          :href="action.linkUri"
                          target="_blank"
                        >
                          {{ action.linkUri }}
                        </a>
                      </div>
                      <div style="padding: 0rem 1rem 0.4rem 1rem;">
                        <b-button
                          v-if="action.text && action.type === 'message'"
                          size="md"
                          variant="info"
                          @click="onMessage(action, 'action')"
                        >
                          {{ action.text }}
                        </b-button>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col
          ref="refActionCard"
          cols="6"
          class="m-0 p-0"
        >
          <b-card
            class="m-0 ml-1 p-1"
            style="border-radius: 1rem;"
            no-body
          >
            <div style="padding: 0 1rem 0 1rem;">
              <span style="font-size: 18px; font-weight: 500;">
                {{ actionTitle }}
              </span>
            </div>
            <div style="padding: 0.4rem 1rem 0.4rem 1rem;">
              <a
                v-if="actionUrlLink !== ''"
                :href="actionUrlLink"
                target="_blank"
              >
                {{ actionUrlLink }}
              </a>
            </div>
            <div style="padding: 0rem 1rem 0.4rem 1rem;">
              <b-badge
                v-if="actionText !== ''"
                variant="info"
                style="padding: 10px;"
              >
                {{ actionText }}
              </b-badge>
            </div>
            <div style="padding: 0 0 0 0;">
              <line-chat-message
                v-if="actionMessage !== null"
                :data="actionMessage"
                @action="onActionMessage"
              />
            </div>
            <div
              v-if="actionData !== null"
              class="mt-1"
            >
              <div>
                <b-tabs>
                  <b-tab
                    v-for="(action, index) in actionData"
                    :key="index"
                    :title="`${$t('Area')} ${index+1}`"
                  >
                    <div
                      style="border-radius: 0.8rem; border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                    >
                      <b-form-group
                        v-if="action.area"
                        :label="$t('Area')"
                      >
                        <div
                          style="border: 1px solid #eeeeee; padding: 12px 12px 12px 12px; margin-bottom: 0.5rem;"
                        >
                          <b-row>
                            <b-col
                              v-for="(value, key) in action.area"
                              :key="key"
                            >
                              <span>{{ key }}</span>
                              <b-form-input
                                :value="value"
                                style="margin-top: 5px;"
                              />
                            </b-col>
                          </b-row>
                        </div>
                      </b-form-group>
                      <div style="padding: 0 1rem 0 1rem;">
                        <span style="font-size: 18px; font-weight: 500;">
                          {{ $t(textFirstUpper(action.type)) }}
                        </span>
                      </div>
                      <div style="padding: 0.4rem 1rem 0.4rem 1rem;">
                        <a
                          v-if="action.linkUri && action.type === 'uri'"
                          :href="action.linkUri"
                          target="_blank"
                        >
                          {{ action.linkUri }}
                        </a>
                      </div>
                      <div style="padding: 0rem 1rem 0.4rem 1rem;">
                        <b-button
                          v-if="action.text && action.type === 'message'"
                          size="md"
                          variant="info"
                          @click="onMessage(action, 'json')"
                        >
                          {{ action.text }}
                        </b-button>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BButton,
  BTab,
  BTabs,
  BFormInput,
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import replymessageStoreModule from './replymessageStoreModule'
import chatmessageStoreModule from '../chatmessage/chatmessageStoreModule'

export default {
  components: {
    LineChatMessage,

    BRow,
    BCol,
    BCard,
    BOverlay,
    BButton,
    BTab,
    BTabs,
    BFormInput,
    BFormGroup,
    BBadge,
  },
  data() {
    return {
      showOverlay: false,
      indexMessage: 0,
      jsonMessage: {},
      lineChatMessage: [],
      lineChatMessage1: {
        type: 'flex',
        altText: 'test',
        contents: {
          type: 'bubble',
          hero: {
            type: 'image', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png', size: 'full', aspectRatio: '20:13', aspectMode: 'cover', action: { type: 'uri', label: 'Line', uri: 'https://linecorp.com/' },
          },
          body: {
            type: 'box',
            layout: 'vertical',
            contents: [{
              type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
            }, {
              type: 'box',
              layout: 'baseline',
              margin: 'md',
              contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
              }],
            }, {
              type: 'box',
              layout: 'vertical',
              spacing: 'sm',
              margin: 'lg',
              contents: [{
                type: 'box',
                layout: 'baseline',
                spacing: 'sm',
                contents: [{
                  type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                }, {
                  type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                }],
              }, {
                type: 'box',
                layout: 'baseline',
                spacing: 'sm',
                contents: [{
                  type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                }, {
                  type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                }],
              }],
            }],
          },
          footer: {
            type: 'box',
            layout: 'vertical',
            flex: 0,
            spacing: 'sm',
            contents: [{
              type: 'button',
              action: {
                type: 'postback', label: 'CALL', text: 'waiting', data: 'type=rm&id=4',
              },
              height: 'sm',
              style: 'link',
            }, {
              type: 'button', action: { type: 'uri', label: 'WEBSITE', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
            }, { type: 'spacer', size: 'sm' }],
          },
        },
      },
      lineChatMessage2: {
        type: 'flex',
        altText: 'test',
        contents: {
          type: 'carousel',
          contents: [{
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: {
                type: 'postback', label: 'Line1', text: 'waiting', data: 'type=rm&id=4',
              },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [{
                type: 'button',
                action: {
                  type: 'postback', label: 'CALL1', text: 'waiting', data: 'type=rm&id=3',
                },
                height: 'sm',
                style: 'link',
              }, {
                type: 'button', action: { type: 'uri', label: 'WEBSITE1', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
              }, { type: 'spacer', size: 'sm' }],
            },
          }, {
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: { type: 'uri', label: 'Line2', uri: 'https://linecorp.com/' },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [
                {
                  type: 'button',
                  action: {
                    type: 'postback', label: 'CALL2', text: 'waiting', data: 'type=rm&id=4',
                  },
                  height: 'sm',
                  style: 'link',
                }, {
                  type: 'button',
                  action: {
                    type: 'message',
                    label: 'MESSAGE2',
                    text: 'contact',
                  },
                  height: 'sm',
                  style: 'link',
                }, { type: 'spacer', size: 'sm' }],
            },
          }, {
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: { type: 'uri', label: 'Line3', uri: 'https://linecorp.com/' },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [{
                type: 'button',
                action: {
                  type: 'postback', label: 'CALL3', text: 'waiting', data: 'type=cm&id=5',
                },
                height: 'sm',
                style: 'link',
              }, {
                type: 'button', action: { type: 'uri', label: 'WEBSITE3', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
              }, { type: 'spacer', size: 'sm' }],
            },
          }, {
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: { type: 'uri', label: 'Line4', uri: 'https://linecorp.com/' },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [{
                type: 'button',
                action: { type: 'uri', label: 'CALL4', uri: 'https://linecorp.com' },
                height: 'sm',
                style: 'link',
              }, {
                type: 'button', action: { type: 'uri', label: 'WEBSITE4', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
              }, { type: 'spacer', size: 'sm' }],
            },
          }, {
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: { type: 'uri', label: 'Line5', uri: 'https://linecorp.com/' },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [{
                type: 'button',
                action: { type: 'uri', label: 'CALL5', uri: 'https://linecorp.com' },
                height: 'sm',
                style: 'link',
              }, {
                type: 'button', action: { type: 'uri', label: 'WEBSITE5', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
              }, { type: 'spacer', size: 'sm' }],
            },
          }, {
            type: 'bubble',
            hero: {
              type: 'image',
              url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
              size: 'full',
              aspectRatio: '20:13',
              aspectMode: 'cover',
              action: { type: 'uri', label: 'Line6', uri: 'https://linecorp.com/' },
            },
            body: {
              type: 'box',
              layout: 'vertical',
              contents: [{
                type: 'text', text: 'Brown Cafe', weight: 'bold', size: 'xl', contents: [],
              }, {
                type: 'box',
                layout: 'baseline',
                margin: 'md',
                contents: [{ type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gold_star_28.png', size: 'sm' }, { type: 'icon', url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/review_gray_star_28.png', size: 'sm' }, {
                  type: 'text', text: '4.0', size: 'sm', color: '#999999', flex: 0, margin: 'md', contents: [],
                }],
              }, {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                margin: 'lg',
                contents: [{
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Place', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: 'Miraina Tower, 4-1-6 Shinjuku, Tokyo', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }, {
                  type: 'box',
                  layout: 'baseline',
                  spacing: 'sm',
                  contents: [{
                    type: 'text', text: 'Time', size: 'sm', color: '#AAAAAA', flex: 1, contents: [],
                  }, {
                    type: 'text', text: '10:00 - 23:00', size: 'sm', color: '#666666', flex: 5, wrap: true, contents: [],
                  }],
                }],
              }],
            },
            footer: {
              type: 'box',
              layout: 'vertical',
              flex: 0,
              spacing: 'sm',
              contents: [{
                type: 'button',
                action: {
                  type: 'postback', label: 'CALL6', text: 'waiting', data: 'type=rm&id=4',
                },
                height: 'sm',
                style: 'link',
              }, {
                type: 'button', action: { type: 'uri', label: 'WEBSITE6', uri: 'https://linecorp.com' }, height: 'sm', style: 'link',
              }, { type: 'spacer', size: 'sm' }],
            },
          }],
        },
      },
      actionData: null,
      replymessageData: null,
      chatmessageData: null,
      actionMessage: null,
      actionUrlLink: '',
      actionTitle: '',
      actionText: '',
      jsonData: null,
    }
  },
  mounted() {
    this.indexMessage = 0
    this.lineChatMessage[this.indexMessage] = this.lineChatMessage2
    this.jsonMessage = this.lineChatMessage[this.indexMessage]
    if (this.jsonMessage.type === 'imagemap') {
      const { actions } = this.jsonMessage
      this.jsonData = actions
    }
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    onAction(action) {
      this.replymessageData = null
      this.chatmessageData = null
      this.actionMessage = null
      this.actionUrlLink = ''
      this.actionText = ''
      this.actionTitle = ''
      this.actionData = null
      const { label } = action
      if (label) {
        this.actionTitle = label
      }
      const { type } = action
      if (type === 'postback') {
        const { data } = action
        if (data) {
          const params = new URLSearchParams(data)
          const typeData = params.get('type')
          const idData = params.get('id')
          if (typeData !== null && typeData !== undefined && idData !== null && idData !== undefined) {
            if (typeData === 'rm') {
              this.getReplymessage({ language: this.$i18n.locale, id: idData })
            } else if (typeData === 'cm') {
              this.getChatmessage(idData)
            }
          }
        }
      } else if (type === 'uri') {
        const { uri } = action
        this.actionUrlLink = uri
      } else if (type === 'message') {
        const { text } = action
        this.actionText = text

        const q = `is_deleted = 0 AND type = 'message' AND JSON_SEARCH(keyword, 'one', '${text}') IS NOT NULL`
        this.getReplymessage({ language: this.$i18n.locale, q })
      }
      this.$refs.refActionCard.scrollIntoView()
    },
    getReplymessage(params) {
      this.showOverlay = true
      store
        .dispatch('replymessage-store/getReplymessage', params)
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.replymessageData = response.data
          const { message } = response.data
          if (message) {
            this.actionMessage = message
            if (this.actionMessage.type === 'imagemap') {
              const { actions } = this.actionMessage
              this.actionData = actions
            }
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    getChatmessage(id) {
      this.showOverlay = true
      store
        .dispatch('chatmessage-store/getChatmessage', { language: this.$i18n.locale, id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.chatmessageData = response.data
          const { message } = response.data
          if (message) {
            this.actionMessage = message
            if (this.actionMessage.type === 'imagemap') {
              const { actions } = this.actionMessage
              this.actionData = actions
            }
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onActionMessage(action) {
      this.indexMessage += 1
      this.lineChatMessage[this.indexMessage] = this.actionMessage
      this.jsonMessage = this.lineChatMessage[this.indexMessage]

      if (this.jsonMessage.type === 'imagemap') {
        const { actions } = this.jsonMessage
        this.jsonData = actions
      }

      this.onAction(action)
    },
    onPrev() {
      this.indexMessage -= 1
      this.jsonMessage = this.lineChatMessage[this.indexMessage]

      this.replymessageData = null
      this.chatmessageData = null
      this.actionMessage = null
      this.actionUrlLink = ''
      this.actionText = ''
      this.actionTitle = ''
      this.actionData = null
      this.jsonData = null

      if (this.jsonMessage.type === 'imagemap') {
        const { actions } = this.jsonMessage
        this.jsonData = actions
      }
    },
    onMessage(action, target) {
      const { text } = action
      // const q = `context = "${text}"`
      const q = `is_deleted = 0 AND type = 'message' AND JSON_SEARCH(keyword, 'one', '${text}') IS NOT NULL`
      this.showOverlay = true
      store
        .dispatch('replymessage-store/getReplymessage', { language: this.$i18n.locale, q })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false

          this.replymessageData = response.data
          const { message } = response.data
          if (message) {
            this.chatmessageData = null
            this.actionUrlLink = ''
            this.actionText = ''
            this.actionTitle = ''
            this.actionData = null

            if (target === 'json') {
              this.indexMessage += 1
              this.lineChatMessage[this.indexMessage] = this.actionMessage
              this.jsonMessage = this.lineChatMessage[this.indexMessage]

              if (this.jsonMessage.type === 'imagemap') {
                const { actions } = this.jsonMessage
                this.jsonData = actions
              }

              this.actionMessage = message
              this.actionText = text
            } else {
              this.actionMessage = message
              this.actionText = text

              if (this.actionMessage.type === 'imagemap') {
                const { actions } = this.actionMessage
                this.actionData = actions
              }
            }
          } else {
            const errorMsg = this.$i18n.t('Not found Reply Message')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
  },
  setup() {
    if (!store.hasModule('replymessage-store')) store.registerModule('replymessage-store', replymessageStoreModule)
    if (!store.hasModule('chatmessage-store')) store.registerModule('chatmessage-store', chatmessageStoreModule)
    onUnmounted(() => {
      if (store.hasModule('replymessage-store')) store.unregisterModule('replymessage-store')
      if (store.hasModule('chatmessage-store')) store.unregisterModule('chatmessage-store')
    })
    return {}
  },
}
</script>

<style>
</style>
